import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview"
    }}>{`Overview`}</h1>
    <p><em parentName="p">{`ManiaLegends`}</em>{` is a team-based mode (3v3) for the game `}<a parentName="p" {...{
        "href": "https://www.ubisoft.com/fr-ca/game/shoot-mania-storm/"
      }}>{`ShootMania`}</a>{`.`}<br parentName="p"></br>{`
`}{`The last release (0.5.0) can be downloaded on the manialink "la-lco".`}</p>
    <p>{`This mode is strongly inspired by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Multiplayer_online_battle_arena"
      }}>{`MOBA`}</a>{` games. Each team is assisted by relatively weak computer-controlled units, called "bots", that periodically spawn at both spawns, marching down predefined paths.
Players start the game with only 1 Armor and 1 Rocket and can buy items in the shop to increase their stats. A game lasts about 20 minutes.`}</p>
    <p><em parentName="p">{`Note: It is recommended to disable the `}<strong parentName="em">{`Use custom player color`}</strong>{` option to play this mode.`}</em></p>
    <h2 {...{
      "id": "objective"
    }}>{`Objective`}</h2>
    <p>{`To win the game, `}<strong parentName="p">{`capture the `}<a parentName="strong" {...{
          "href": "/poles#team-poles"
        }}>{`enemy pole`}</a></strong>{`. A team can capture the opponent pole only when all the enemy's `}<a parentName="p" {...{
        "href": "/bots#guardians"
      }}>{`guardians`}</a>{` are eliminated.
Take down the enemy’s guardians and capture this pole to win the game.`}</p>
    <h2 {...{
      "id": "game-start"
    }}>{`Game start`}</h2>
    <p><a parentName="p" {...{
        "href": "/bots#team-bots"
      }}>{`Team bots`}</a>{` spawn after 30 seconds. `}<a parentName="p" {...{
        "href": "/bots#gold-bots"
      }}>{`Gold bots`}</a>{` spawn after 40 seconds and the `}<a parentName="p" {...{
        "href": "/poles#bonus-poles"
      }}>{`Bonus Pole`}</a>{` can be loaded after 1 minute.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      